import {getOrigin} from "./CurrentPage";

export function applyMetaTags(tags) {
  const title = tags?.title || "Jafri Library - Free Islamic Books Download";

  window.document.title = title

  document.querySelector('meta[name="description"]').setAttribute("content", tags?.description || "");

  document.querySelector('meta[property="og:title"]').setAttribute("content", title);
  document.querySelector('meta[property="og:description"]').setAttribute("content", tags?.description || "");
  document.querySelector('meta[property="og:siteName"]').setAttribute("content", tags?.siteName || "Jafri Library");
  document.querySelector('meta[property="og:image"]').setAttribute("content", tags?.image || getOrigin() + "/logo-og.png");

}

export function refreshMetaTags(path) {
  fetch('/api/meta' + path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(response.status.toString())
    }
  })
  .then(applyMetaTags)
  .catch(error => applyMetaTags({}));
}

